// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    appVersion: require("../../package.json").version,
    production: true,
    environment: 'prod',

    apiBase: "https://depotapi.direct-collect.com.au",
    apiBaseCustomer: "https://api.direct-collect.com.au",
    Cognito: {
      domain: "direct-collect-prod-admin-domain.auth.ap-southeast-2.amazoncognito.com",
      userPoolId: "ap-southeast-2_ZkRbmabru",
      userPoolWebClientId: "2mm1it7li8nrmpumuu4tmpo8ld",
      redirectSignIn: "https://admin.direct-collect.com.au/user-home",
      redirectSignOut: "https://admin.direct-collect.com.au"
    },
    routeOptimization: {
      baseUrl: 'https://flexops-api.adionatech.com/api/v1'
    },
    S3: {
      bucket: 'direct-collect-prod-admin-cds-s3',
      identityPoolId: 'ap-southeast-2:8e44feb4-f808-4d19-8713-d8cfde839989'
    },

    stripe_pk: 'pk_live_51LZ2OoJWH8z1ad0RWqLJpaZ0Ovyysc7CNYBid4cR4KLIqSCowag0oByhu81rPRUjcEYooyw6iIGRi9VaKHwRPBa4004yRB5Mmc',
    maps_key: 'AIzaSyCoi9BscJgb32_p3ZoWlW5d1Ll6eaxGq4g'
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  //
  //
  // appVersion: require('../../package.json').version,

